<template>
  <div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          ><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item
        >
        <el-breadcrumb-item>全场活动</el-breadcrumb-item>
        <el-breadcrumb-item>{{ mybreadcrumb }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{
          redactVal ? "修改活动" : "新增活动"
        }}</el-breadcrumb-item>
      </el-breadcrumb>
      <div style="padding: 5px">
        <i class="el-icon-close icon" @click="closeClick"></i>
      </div>
    </div>
    <div style="padding: 50px; padding-right: 10%">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <!-- 满减活动 -->
        <div v-if="mybreadcrumb == '满减活动'">
          <el-form-item label="活动标题" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="消费金额" prop="Monetary">
            <el-input v-model="ruleForm.Monetary"></el-input>
          </el-form-item>
          <el-form-item label="满减金额" prop="reduction" style="width: 20%">
            <el-input v-model="ruleForm.reduction"></el-input>
          </el-form-item>
          <el-form-item label="" prop="type">
            <el-checkbox v-model="ruleForm.checked1"
              >允许单品活动重叠生效</el-checkbox
            >
            <el-checkbox v-model="ruleForm.checked2"
              >只限用户首单生效</el-checkbox
            >
          </el-form-item>
          <el-form-item label="状态" prop="radio">
            <el-radio-group v-model="radio">
              <el-radio label="待发布">待发布</el-radio>
              <el-radio label="进行中">进行中</el-radio>
              <el-radio label="已关闭">已关闭</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="优先级" prop="priority" style="width: 25%">
            <el-input v-model="ruleForm.priority"></el-input>
          </el-form-item>
          <el-form-item label="活动规则描述" prop="desc" style="width: 30%">
            <el-input
              type="textarea"
              v-model="ruleForm.desc"
              :rows="5"
            ></el-input>
          </el-form-item>
        </div>
        <!-- 满折活动 -->
        <div v-if="mybreadcrumb == '满折活动'">
          <el-form-item label="活动标题" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="消费金额" prop="Monetary">
            <el-input v-model="ruleForm.Monetary"></el-input>
          </el-form-item>
          <el-form-item label="折扣(1-10)" prop="discount">
            <el-input v-model="ruleForm.discount"></el-input>
          </el-form-item>
          <el-form-item label="" prop="type">
            <el-checkbox v-model="ruleForm.checked1"
              >允许单品活动重叠生效</el-checkbox
            >
            <el-checkbox v-model="ruleForm.checked2"
              >只限用户首单生效</el-checkbox
            >
          </el-form-item>
          <el-form-item label="状态" prop="radio">
            <el-radio-group v-model="radio">
              <el-radio label="待发布">待发布</el-radio>
              <el-radio label="进行中">进行中</el-radio>
              <el-radio label="已关闭">已关闭</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="优先级" prop="priority" style="width: 25%">
            <el-input v-model="ruleForm.priority"></el-input>
          </el-form-item>
          <el-form-item label="活动规则描述" prop="desc" style="width: 30%">
            <el-input
              type="textarea"
              v-model="ruleForm.desc"
              :rows="5"
            ></el-input>
          </el-form-item>
        </div>
        <!-- 满赠活动 -->
        <div v-if="mybreadcrumb == '满赠活动'">
          <el-form-item label="活动标题" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="消费金额" prop="Monetary">
            <el-input v-model="ruleForm.Monetary"></el-input>
          </el-form-item>
          <el-form-item label="" prop="type">
            <el-checkbox v-model="ruleForm.checked1"
              >允许单品活动重叠生效</el-checkbox
            >
            <el-checkbox v-model="ruleForm.checked2"
              >只限用户首单生效</el-checkbox
            >
          </el-form-item>
          <el-form-item label="状态" prop="radio">
            <el-radio-group v-model="radio">
              <el-radio label="待发布">待发布</el-radio>
              <el-radio label="进行中">进行中</el-radio>
              <el-radio label="已关闭">已关闭</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="赠送礼券" prop="ticket" style="width: 300%">
            <el-select v-model="ruleForm.ticket" @change="ticketChange">
              <el-option
                :label="tck.wt_name"
                :value="tck.id"
                v-for="(tck, index) in tickets"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <div style="display: flex">
            <el-form-item label="赠品" prop="gift">
              <el-select
                v-model="ruleForm.gift"
                @change="giftChange"
                style="width: 100% !important"
              >
                <el-option
                  :label="gif.product_name"
                  :value="gif.id"
                  v-for="(gif, index) in gifts"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="赠送数量" prop="giftNum" style="width: 15%">
              <el-input v-model="ruleForm.giftNum"></el-input>
            </el-form-item>
          </div>
          <el-form-item label="优先级" prop="priority" style="width: 25%">
            <el-input v-model="ruleForm.priority"></el-input>
          </el-form-item>
          <el-form-item label="活动规则描述" prop="desc" style="width: 30%">
            <el-input
              type="textarea"
              v-model="ruleForm.desc"
              :rows="5"
            ></el-input>
          </el-form-item>
        </div>
        <!-- 满包邮活动 -->
        <div v-if="mybreadcrumb == '满包邮活动'">
          <el-form-item label="活动标题" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="满包邮金额" prop="delivery">
            <el-input v-model="ruleForm.delivery"></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="radio">
            <el-radio-group v-model="radio">
              <el-radio label="待发布">待发布</el-radio>
              <el-radio label="进行中">进行中</el-radio>
              <el-radio label="已关闭">已关闭</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="优先级" prop="priority" style="width: 25%">
            <el-input v-model="ruleForm.priority"></el-input>
          </el-form-item>
          <el-form-item label="活动规则描述" prop="desc" style="width: 30%">
            <el-input
              type="textarea"
              v-model="ruleForm.desc"
              :rows="5"
            ></el-input>
          </el-form-item>
        </div>
        <!-- 二维码活动 -->
        <div v-if="mybreadcrumb == '二维码活动'">
          <el-form-item label="活动标题" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="缩例图" prop="pic">
            <el-upload
              class="avatar-uploader"
              action="#"
              :show-file-list="false"
              :http-request="uploadImg"
            >
              <img
                v-if="imageUrl"
                :src="'http://192.168.3.3' + imageUrl"
                class="avatar"
              />
              <el-button size="small" type="primary" class="uploadBtu"
                >选择图片</el-button
              >
            </el-upload>
          </el-form-item>
          <div style="display: flex">
            <el-form-item label="二维码段" required>
              <el-col :span="11">
                <el-form-item prop="code1">
                  <el-input v-model="ruleForm.code1"></el-input>
                </el-form-item>
              </el-col>
              <el-col
                class="line"
                :span="2"
                style="display: flex; justify-content: center"
                >-</el-col
              >
              <el-col :span="11">
                <el-form-item prop="code2">
                  <el-input v-model="ruleForm.code2"></el-input>
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item label="推广人" required>
              <el-button type="success">绑定</el-button>
            </el-form-item>
          </div>
          <div style="display: flex">
            <el-form-item label="充值面额" prop="pay">
              <el-input v-model="ruleForm.pay"></el-input>
            </el-form-item>
            <el-form-item label="升级至" prop="upgrade" required>
              <el-select
                v-model="ruleForm.upgrade"
                style="width: 38% !important"
              >
                <el-option
                  :label="upg"
                  :value="upg"
                  v-for="(upg, index) in upgrades"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <el-form-item>
            <el-checkbox-group v-model="ruleForm.upgradeFlag">
              <el-checkbox
                label="允许活动重叠生效"
                name="upgradeFlag"
              ></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <div style="display: flex">
            <el-form-item label="赠送礼券" prop="ticket">
              <el-select
                v-model="ruleForm.ticket"
                style="width: 50% !important"
              >
                <el-option
                  :label="tck"
                  :value="tck"
                  v-for="(tck, index) in tickets"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="赠送数量" prop="giftNum" style="width: 15%">
              <el-input v-model="ruleForm.giftNum"></el-input>
            </el-form-item>
          </div>
          <el-form-item label="优先级" prop="priority" style="width: 25%">
            <el-input v-model="ruleForm.priority"></el-input>
          </el-form-item>
          <el-form-item label="活动规则描述" prop="desc" style="width: 30%">
            <el-input
              type="textarea"
              v-model="ruleForm.desc"
              :rows="5"
            ></el-input>
          </el-form-item>
        </div>
        <!-- 消费送活动 -->
        <div v-if="mybreadcrumb == '消费送活动'">
          <el-form-item label="活动标题" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="消费金额" prop="Monetary">
            <el-input v-model="ruleForm.Monetary"></el-input>
          </el-form-item>
          <el-form-item label="" prop="type">
            <el-checkbox v-model="ruleForm.checked1"
              >允许单品活动重叠生效</el-checkbox
            >
            <el-checkbox v-model="ruleForm.checked2"
              >只限用户首单生效</el-checkbox
            >
          </el-form-item>
          <el-form-item label="状态" prop="radio">
            <el-radio-group v-model="radio">
              <el-radio label="待发布">待发布</el-radio>
              <el-radio label="进行中">进行中</el-radio>
              <el-radio label="已关闭">已关闭</el-radio>
            </el-radio-group>
          </el-form-item>
          <div style="display: flex">
            <el-form-item label="赠送礼券" prop="ticket">
              <el-select
                v-model="ruleForm.ticket"
                style="width: 60% !important"
                @change="ticketChange"
              >
                <el-option
                  :label="tck.wt_name"
                  :value="tck.id"
                  v-for="(tck, index) in tickets"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="赠送数量" prop="giftNum" style="width: 15%">
              <el-input v-model="ruleForm.giftNum"></el-input>
            </el-form-item>
          </div>
          <el-form-item label="优先级" prop="priority" style="width: 25%">
            <el-input v-model="ruleForm.priority"></el-input>
          </el-form-item>
          <el-form-item label="活动规则描述" prop="desc" style="width: 30%">
            <el-input
              type="textarea"
              v-model="ruleForm.desc"
              :rows="5"
            ></el-input>
          </el-form-item>
        </div>
        <!-- 新手礼包活动 -->
        <div v-if="mybreadcrumb == '新手礼包活动'">
          <el-form-item label="活动标题" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="缩例图" prop="pic">
            <el-upload
              class="avatar-uploader"
              action="#"
              :show-file-list="false"
              :http-request="uploadImg"
            >
              <img
                v-if="imageUrl"
                :src="'http://192.168.3.3' + imageUrl"
                class="avatar"
              />
              <el-button size="small" type="primary" class="uploadBtu"
                >选择图片</el-button
              >
            </el-upload>
          </el-form-item>
          <div style="display: flex">
            <el-form-item label="赠送礼券" prop="ticket">
              <el-select
                v-model="ruleForm.ticket"
                style="width: 70% !important"
                @change="ticketChange"
              >
                <el-option
                  :label="tck.wt_name"
                  :value="tck.id"
                  v-for="(tck, index) in tickets"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="赠送数量" prop="giftNum" style="width: 15%">
              <el-input v-model="ruleForm.giftNum"></el-input>
            </el-form-item>
            <el-form-item
              label="月限可用量"
              prop="monthlyLimit"
              style="width: 18%; margin-left: 50px"
            >
              <el-input
                v-model="ruleForm.monthlyLimit"
                placeholder="0代表不限"
              ></el-input>
            </el-form-item>
          </div>
          <el-form-item label="激活间隔" prop="interval" style="width: 18%">
            <div style="display: flex">
              <el-input
                v-model="ruleForm.interval"
                placeholder="0代表不限"
              ></el-input>
              <span>天</span>
            </div>
          </el-form-item>
          <el-form-item label="有效期" prop="indate" style="width: 18%">
            <div style="display: flex">
              <el-input
                v-model="ruleForm.indate"
                placeholder="0代表不限"
              ></el-input>
              <span>天</span>
            </div>
          </el-form-item>
          <el-form-item label="优先级" prop="priority" style="width: 25%">
            <el-input v-model="ruleForm.priority"></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="radio">
            <el-radio-group v-model="radio">
              <el-radio label="待发布">待发布</el-radio>
              <el-radio label="进行中">进行中</el-radio>
              <el-radio label="已关闭">已关闭</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="活动规则描述" prop="desc" style="width: 30%">
            <el-input
              type="textarea"
              v-model="ruleForm.desc"
              :rows="5"
            ></el-input>
          </el-form-item>
        </div>
        <!-- 弹窗活动 -->
        <div v-if="mybreadcrumb == '弹窗活动'">
          <el-form-item label="活动标题" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="缩例图" prop="pic">
            <el-upload
              class="avatar-uploader"
              action="#"
              :show-file-list="false"
              :http-request="uploadImg"
            >
              <img
                v-if="imageUrl"
                :src="'http://192.168.3.3' + imageUrl"
                class="avatar"
              />
              <el-button size="small" type="primary" class="uploadBtu"
                >选择图片</el-button
              >
            </el-upload>
          </el-form-item>
          <div style="display: flex">
            <el-form-item label="路径" prop="path">
              <el-input v-model="ruleForm.path"></el-input>
            </el-form-item>
            <el-form-item label="弹窗方式" prop="popup">
              <el-select
                v-model="ruleForm.popup"
                style="width: 70% !important"
                @change="popupChange"
              >
                <el-option
                  :label="ppp"
                  :value="index"
                  v-for="(ppp, index) in popups"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div style="display: flex">
            <el-form-item label="赠送礼券" prop="ticket">
              <el-select
                v-model="ruleForm.ticket"
                style="width: 70% !important"
                @change="ticketChange"
              >
                <el-option
                  :label="tck.wt_name"
                  :value="tck.id"
                  v-for="(tck, index) in tickets"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="赠送数量" prop="giftNum" style="width: 15%">
              <el-input v-model="ruleForm.giftNum"></el-input>
            </el-form-item>
            <el-form-item
              label="月限可用量"
              prop="monthlyLimit"
              style="width: 18%; margin-left: 50px"
            >
              <el-input
                v-model="ruleForm.monthlyLimit"
                placeholder="0代表不限"
              ></el-input>
            </el-form-item>
          </div>
          <el-form-item label="激活间隔" prop="interval" style="width: 18%">
            <div style="display: flex">
              <el-input
                v-model="ruleForm.interval"
                placeholder="0代表不限"
              ></el-input>
              <span>天</span>
            </div>
          </el-form-item>
          <el-form-item label="有效期" prop="indate" style="width: 18%">
            <div style="display: flex">
              <el-input
                v-model="ruleForm.indate"
                placeholder="0代表不限"
              ></el-input>
              <span>天</span>
            </div>
          </el-form-item>
          <el-form-item label="优先级" prop="priority" style="width: 25%">
            <el-input v-model="ruleForm.priority"></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="radio">
            <el-radio-group v-model="radio">
              <el-radio label="待发布">待发布</el-radio>
              <el-radio label="进行中">进行中</el-radio>
              <el-radio label="已关闭">已关闭</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="活动规则描述" prop="desc" style="width: 30%">
            <el-input
              type="textarea"
              v-model="ruleForm.desc"
              :rows="5"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import qs from "qs";
import axios from "axios";
import { urlList } from "./data";
export default {
  props: ["breadcrumb", "redactVal"],
  data() {
    return {
      ruleForm: {
        name: "",
        Monetary: "",
        // 不同新赠活动表单
        reduction: "",
        discount: "",
        ticket: "--选择--",
        gift: "--选择--",
        ticketId: "",
        giftId: "",
        giftNum: 0,
        delivery: "",
        pic: "",
        code1: "",
        code2: "",
        pay: "",
        upgrade: "vip0",
        upgradeFlag: "",
        monthlyLimit: "",
        indate: "",
        path: "",
        popup: "自由领取",
        popupId: "0",
        // end
        type: [],
        interval: "",
        priority: "",
        desc: "",
        checked1: true,
        checked2: true,
      },
      rules: {
        name: [{ required: true, message: "这是必填字段" }],
        Monetary: [{ required: true, message: "这是必填字段" }],
        reduction: [{ required: true, message: "这是必填字段" }],
        gift: [{ required: true, message: "这是必填字段" }],
        code1: [{ required: true, message: "这是必填字段" }],
        code2: [{ required: true, message: "这是必填字段" }],
        pay: [{ required: true, message: "这是必填字段" }],
        delivery: [{ required: true, message: "这是必填字段" }],
        indate: [{ required: true, message: "这是必填字段" }],
        path: [{ required: true, message: "这是必填字段" }],
        popup: [{ required: true, message: "这是必填字段" }],
        discount: [{ required: true, message: "这是必填字段" }],
        priority: [{ required: true, message: "这是必填字段" }],
      },

      // 不同活动的新增
      tickets: [],
      gifts: [],
      imageUrl: "",
      upgrades: ["vip0", "vip1", "vip2", "vip3", "vip4", "vip5"],
      popups: ["自由领取", "广告", "导航"],
      //end
      baseUrl: "",
      radio: "待发布",
      id: 0,
      type: "",
      commercial_id: "",
    };
  },
  mounted() {
    this.commercial_id =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    if (this.$props.breadcrumb == "满赠活动") {
      axios
        .all([
          this.$http.post(
            "/product/lst",
            qs.stringify({
              currentPage: 0,
              currentLength: 0,
              commercial_id: this.commercial_id,
            })
          ),
          this.$http.post(
            "/water_ticket/lst",
            qs.stringify({ currentPage: 0, currentLength: 0 })
          ),
        ])
        .then(
          axios.spread((res1, res2) => {
            this.gifts = res1.data.data;
            this.tickets = res2.data.data;
          })
        );
    } else if (
      this.$props.breadcrumb == "消费送活动" ||
      this.$props.breadcrumb == "新手礼包活动" ||
      this.$props.breadcrumb == "弹窗活动"
    ) {
      this.$http
        .post(
          "/water_ticket/lst",
          qs.stringify({ currentPage: 0, currentLength: 0 })
        )
        .then((res) => {
          this.tickets = res.data.data;
        });
    }
    urlList.forEach((item) => {
      if (item.title == this.$props.breadcrumb) {
        this.baseUrl = item.url;
        this.type = item.type;
      }
    });
    console.log(this.$props.redactVal);
    if (this.$props.redactVal) {
      const row = this.$props.redactVal;
      this.id = row.id;
      this.ruleForm.name = row.activity_title;
      this.ruleForm.Monetary = row.consumption_amount;
      this.ruleForm.checked1 = row.is_overlap == 0 ? false : true;
      this.ruleForm.checked2 = row.is_first_order == 0 ? false : true;
      this.ruleForm.priority = row.priority;
      this.ruleForm.desc = row.activity_rule_description;
      this.radio = row.status;
      this.type = row.activity_type;
    }
    if (this.$props.redactVal && this.$props.breadcrumb == "满减活动") {
      const row = this.$props.redactVal;
      this.ruleForm.reduction = row.full_reduction_amount;
    } else if (this.$props.redactVal && this.$props.breadcrumb == "满折活动") {
      const row = this.$props.redactVal;
      this.ruleForm.discount = row.discount;
    } else if (this.$props.redactVal && this.$props.breadcrumb == "满赠活动") {
      const row = this.$props.redactVal;
      this.ruleForm.gift = row.gift[0].product_name;
      this.ruleForm.giftNum = row.number;
      this.ruleForm.ticket = row.gift_wt_id[0].wt_name;
      this.ruleForm.giftId = row.gift[0].id;
      this.ruleForm.ticketId = row.gift_wt_id[0].id;
    } else if (
      this.$props.redactVal &&
      this.$props.breadcrumb == "满包邮活动"
    ) {
      const row = this.$props.redactVal;
      this.ruleForm.delivery = row.full_free_shipping_amount;
    } else if (
      this.$props.redactVal &&
      this.$props.breadcrumb == "消费送活动"
    ) {
      const row = this.$props.redactVal;
      this.ruleForm.ticketId = row.gift_wt_id[0].id;
      this.ruleForm.giftNum = row.gift_wt_number;
      this.ruleForm.ticket = row.gift_wt_id[0].wt_name;
    } else if (
      (this.$props.redactVal && this.$props.breadcrumb == "新手礼包活动") ||
      (this.$props.redactVal && this.$props.breadcrumb == "弹窗活动")
    ) {
      const row = this.$props.redactVal;
      this.imageUrl = row.thumbnail;
      this.ruleForm.ticketId = row.gift_wt_id[0]?.id;
      this.ruleForm.giftNum = row.gift_wt_number;
      this.ruleForm.ticket = row.gift_wt_id[0]?.wt_name;
      this.ruleForm.monthlyLimit =
        row.monthly_available_quantity == 0
          ? ""
          : row.monthly_available_quantity;
      this.ruleForm.interval = row.interval_days == 0 ? "" : row.interval_days;
      this.ruleForm.indate = row.validity_days == 0 ? "" : row.validity_days;
      this.ruleForm.path = row.path;
      this.ruleForm.popupId = row.pop_type;
      this.ruleForm.popup = this.popups[row.pop_type];
    }
  },
  computed: {
    mybreadcrumb() {
      return this.breadcrumb;
    },
  },
  methods: {
    closeClick() {
      this.$emit("close", true);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const subData = {
            activity_title: this.ruleForm.name,
            priority: this.ruleForm.priority,
            id: this.id,
            activity_rule_description: this.ruleForm.desc,
            status: this.radio,
            activity_type: this.type,
          };
          if (this.$props.breadcrumb == "满减活动") {
            subData.full_reduction_amount = this.ruleForm.reduction;
            subData.consumption_amount = this.ruleForm.Monetary;
            subData.is_overlap = this.ruleForm.checked1 ? "1" : "0";
            subData.is_first_order = this.ruleForm.checked2 ? "1" : "0";
          } else if (this.$props.breadcrumb == "满折活动") {
            subData.discount = this.ruleForm.discount;
            subData.consumption_amount = this.ruleForm.Monetary;
            subData.is_overlap = this.ruleForm.checked1 ? "1" : "0";
            subData.is_first_order = this.ruleForm.checked2 ? "1" : "0";
          } else if (this.$props.breadcrumb == "满赠活动") {
            subData.gift = this.ruleForm.giftId;
            subData.number = this.ruleForm.giftNum;
            subData.gift_wt_id = this.ruleForm.ticketId;
            subData.consumption_amount = this.ruleForm.Monetary;
            subData.is_overlap = this.ruleForm.checked1 ? "1" : "0";
            subData.is_first_order = this.ruleForm.checked2 ? "1" : "0";
          } else if (this.$props.breadcrumb == "满包邮活动") {
            subData.full_free_shipping_amount = this.ruleForm.delivery;
          } else if (this.$props.breadcrumb == "消费送活动") {
            subData.is_overlap = this.ruleForm.checked1 ? "1" : "0";
            subData.is_first_order = this.ruleForm.checked2 ? "1" : "0";
            subData.consumption_amount = this.ruleForm.Monetary;
            subData.gift_wt_id = this.ruleForm.ticketId;
            subData.gift_wt_number = this.ruleForm.giftNum;
          } else if (this.$props.breadcrumb == "新手礼包活动") {
            subData.thumbnail = this.imageUrl;
            subData.gift_wt_id = this.ruleForm.ticketId;
            subData.gift_wt_number = this.ruleForm.giftNum;
            subData.monthly_available_quantity =
              this.ruleForm.monthlyLimit == "" ? 0 : this.ruleForm.monthlyLimit;
            subData.interval_days =
              this.ruleForm.interval == "" ? 0 : this.ruleForm.interval;
            subData.validity_days =
              this.ruleForm.indate == "" ? 0 : this.ruleForm.indate;
          } else if (this.$props.breadcrumb == "弹窗活动") {
            subData.thumbnail = this.imageUrl;
            subData.gift_wt_id = this.ruleForm.ticketId;
            subData.gift_wt_number = this.ruleForm.giftNum;
            subData.monthly_available_quantity =
              this.ruleForm.monthlyLimit == "" ? 0 : this.ruleForm.monthlyLimit;
            subData.interval_days =
              this.ruleForm.interval == "" ? 0 : this.ruleForm.interval;
            subData.validity_days =
              this.ruleForm.indate == "" ? 0 : this.ruleForm.indate;
            subData.path = this.ruleForm.path;
            subData.pop_type = this.ruleForm.popupId;
          }
          this.$http
            .post(this.baseUrl + "/save", qs.stringify(subData))
            .then((res) => {
              if (res.data.status == "success") {
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                this.$emit("close", true);
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              }
            });
        } else {
          this.$message({
            message: "请填入必填项",
            type: "warning",
          });
          return false;
        }
      });
    },
    ticketChange(val) {
      this.ruleForm.ticketId = val;
    },
    popupChange(val) {
      this.ruleForm.popupId = val;
    },
    giftChange(val) {
      this.ruleForm.giftId = val;
    },
    csclick(data) {
      console.log(data);
    },
    async uploadImg(file) {
      try {
        const base64 = await this.getBase64(file.file);
        const image = await this.$http.post(
          "uploads_images/up",
          qs.stringify({ file: base64 })
        );
        this.imageUrl = image.data.path;
      } catch (error) {
        console.log(error);
      }
    },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
  },
  watch: {},
};
</script>
<style scoped lang="less">
.icon {
  cursor: pointer;
}

.icon:hover {
  color: red;
}

.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>

<style lang="less" scoped>
.el-input__inner {
  padding: 0 4px !important;
  color: #333 !important;
}

.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #333 !important;
}

.avatar-uploader .el-upload {
  border: 0;
  position: relative;
  overflow: hidden;
  pointer-events: none;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff !important;
}

.avatar {
  width: 78px;
  height: 78px;
  display: block;
  padding: 5px;
  border: 1px solid #dddddd;
}

.uploadBtu {
  pointer-events: auto !important;
  margin-top: 5px !important;
}
</style>
